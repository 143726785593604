import "../styles/faq.css";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import arrowDev from "../assets/icon-arrow-dev.png";
import iconMoins from "../assets/icon-moins-30.png";

import FaqContent from "./Faq/FaqContent";

const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl;
// console.log("baseUrl4200", baseUrl4200);

if (baseUrl4200.includes("3001")) {
  baseUrl4200 = baseUrl.replace("3001", "4200");
} else if (baseUrl.includes("partenaire-preprod")) {
  baseUrl4200 = baseUrl4200.replace("partenaire-preprod", "api");
} else if (baseUrl.includes("partenaire")) {
  baseUrl4200 = baseUrl4200.replace("partenaire", "api");
}

function Faq({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const [recordOptions, setRecordOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [showContent, setShowContent] = useState([]);

  useEffect(() => {
    // Vient récupérer le contenu de chaque FAQ portail dans un tableau et les affiche
    if (user.user === undefined) return;
    user.user.faq.forEach((id) => {
      setLoading(true);
      axios
        .post(
          baseUrl4200 + "/airtableReq/getRecord",
          {
            baseId: "appqe0gy4OaaiygZZ",
            tableName: "tblOtvLbFk8ihhsrc", //FAQ Portail partenaire
            recId: id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data);
            let tmpRecOpt = recordOptions;
            tmpRecOpt.push(res.data.record.fields);
            tmpRecOpt[tmpRecOpt.length - 1].id = res.data.record.id;
            // tmpRecOpt.sort((a, b) => a["Name"].localeCompare(b["Name"]));
            tmpRecOpt.sort((b, a) => {
              // Compare dates
              a["Created time"] = new Date(a["Created time"]);
              b["Created time"] = new Date(b["Created time"]);

              if (a["Created time"] < b["Created time"]) return -1;
              if (a["Created time"] > b["Created time"]) return 1;
              // If dates are equal, compare names
              if (a["Created time"] < b["Created time"]) return -1;
              if (a["Created time"] > b["Created time"]) return 1;
              return 0;
            });
            setRecordOptions([]);
            setRecordOptions(tmpRecOpt);
            console.log(tmpRecOpt);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  }, [user]);

  //   const handleTitleClick = () => {
  //     setShowContent(!showContent);
  //   };

  useEffect(() => {
    console.log("recordOptions");
    console.log(recordOptions);
  }, [recordOptions]);

  console.log("recordOptions2", recordOptions);
  return (
    <div className="container-Dashboard" onClick={() => setResponsiveMenuVisible(false)}>
      <div className="EDL-home-title">
        <p>ENERGIES DE LOIRE - {user.user !== undefined && user.user.company ? user.user.company[0] : null}</p>
        <h2>Une question ?</h2>
      </div>
      {loading ? (
        <div>
          <div id="spinner-modal">
            <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>Merci de patienter...</h4>
            {loadingMsg !== "" ? (
              <p style={{ position: "fixed", top: "calc(50% + 30px)", left: "50%", transform: "translate(-50%, -50%)" }}>{loadingMsg}</p>
            ) : null}
          </div>
        </div>
      ) : null}

      <div className="faq-container">
        <div className="three-color-separator"></div>
        {recordOptions.length > 0 ? (
          recordOptions.map((record, ind) => (
            <div className="faq-content" key={ind}>
              <h3
                className="faq-content-h3"
                onClick={() => {
                  if (!showContent.includes(ind)) {
                    setShowContent([...showContent, ind]);
                  } else {
                    setShowContent(showContent.filter((elt) => elt !== ind));
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={showContent.includes(ind) ? iconMoins : arrowDev} style={{ "vertical-align": "middle" }} />
                {record["Name"]}
              </h3>
              {showContent.includes(ind) && record["Catégorie principale"] ? (
                <h5 style={{ color: "#c1c1c1" }}>
                  <span>#{record["Catégorie principale"]} </span>
                </h5>
              ) : null}
              {showContent.includes(ind) && record["Catégorie(s) secondaire(s)"] ? (
                <h5 style={{ color: "#c1c1c1" }}>
                  {record["Catégorie(s) secondaire(s)"].map((cs, i) => (
                    <span key={i}>#{cs} </span>
                  ))}
                </h5>
              ) : null}
              {showContent.includes(ind) && <FaqContent faqMarkdown={record["Article"]} />}
            </div>
          ))
        ) : (
          <div>
            <p style={{ "text-align": "center" }}>
              Cette section n'est pas renseignée pour l'instant ? N'hésitez pas à revenir ultérieurement. Nos équipes devraient l'alimenter
              rapidement !
            </p>
            <p style={{ "text-align": "center" }}>
              En attendant pour toute question, retrouvez nos coordonnées directement sur votre page d'<a href="./">accueil</a> !
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Faq;
