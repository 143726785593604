import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

import { useState } from "react";
import "../styles/App.css";
import Footer from "./Footer";
import Banner from "./Banner";
import Home from "./Home";
import Connexion from "./Connexion";
import Dashboard from "./Dashboard";
import InProgress from "./InProgress";
import Faq from "./Faq";

// import { useParams } from "react-router-dom";

function App() {
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [user, setUser] = useState({});
  let counter = 0;

  useEffect(() => {
    counter++;
    axios
      .get("/partenaireUser")

      .then((res) => {
        if (res.status === 200) {
          setIsConnected(true);
          setUser(res.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsConnected(false);
      });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/connexion"
            element={
              <div>
                {" "}
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Connexion
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  // getParams={getParams}
                />
              </div>
            }
          />
          <Route
            path="/"
            element={
              <div>
                {" "}
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Home
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/dashboard"
            element={
              <div>
                {" "}
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Dashboard
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/faq"
            element={
              <div>
                {" "}
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Faq
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/in-progress"
            element={
              <div>
                {" "}
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <InProgress
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                />
              </div>
            }
          />
        </Routes>
        <Footer responsiveMenuVisible={responsiveMenuVisible} setResponsiveMenuVisible={setResponsiveMenuVisible} />
      </div>
    </BrowserRouter>
  );
}

export default App;
