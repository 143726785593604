import { HashLink as Link } from "react-router-hash-link";
import EDLLogoFr from "../assets/EDL-logo.png";
import linkedIn_ico from "../assets/linkedin_button.png";
import hamburgerIcon from "../assets/Icon_menu.png";

import "../styles/EDLBanner.css";
import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function EDLBanner({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const baseUrl = window.location.origin;

  const handleHamburgerClick = () => {
    setResponsiveMenuVisible(true);
  };

  const { height, width } = useWindowDimensions();

  return (
    <div className="EDLbanner">
      {/* {window.innerWidth > 990 ? setResponsiveMenuVisible(true) : null} */}
      <div className="EDLlogo-EDL-fr-div">
        <a href="https://partenaire.energiesdeloire.com/" style={{ display: "flex", "text-decoration": "none", color: "white" }}>
          <img src={EDLLogoFr} alt="EDL-logo" />
          <p style={{ margin: "auto 10px" }}>ESPACE PARTENAIRE</p>
        </a>
      </div>
      {/* width: {width} / height : {height} = {width / height} */}
      <div className="EDLmenuNavAndContact">
        <div className="EDLmenuNav">
          {width > 990 ? (
            <ul>
              {isConnected ? (
                <div className="sub-EDLmenuNav" style={{ display: "flex" }}>
                  <li>
                    <a href="./">Accueil</a>
                  </li>
                  <li>
                    <a href="./dashboard">Mes projets</a>
                  </li>
                  <li>
                    <a href="./faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/#contact">Contact</a>
                  </li>
                  <li>
                    <a href={baseUrl + "/api/auth/logout"}>Se déconnecter</a>
                  </li>
                  {user.user !== undefined ? (
                    <li>
                      {user.user.firstname} {user.user.lastname}
                    </li>
                  ) : null}
                </div>
              ) : (
                <li>
                  <a href={baseUrl + "/connexion"}>Se connecter</a>
                </li>
              )}
            </ul>
          ) : responsiveMenuVisible ? (
            <ul>
              {isConnected ? (
                <div className="sub-EDLmenuNav" style={{ display: "flex" }}>
                  <li>
                    <a href="./">Accueil</a>
                  </li>
                  <li>
                    <a href="./dashboard">Mes projets</a>
                  </li>
                  <li>
                    <a href="./faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/#contact">Contact</a>
                  </li>
                  <li>
                    <a href={baseUrl + "/api/auth/logout"}>Se déconnecter</a>
                  </li>
                  {user.user !== undefined ? (
                    <li>
                      {user.user.firstname} {user.user.lastname}
                    </li>
                  ) : null}
                </div>
              ) : (
                <li>
                  <a href={baseUrl + "/connexion"}>Se connecter</a>
                </li>
              )}
            </ul>
          ) : (
            <a className="hamburgerIcon" onClick={handleHamburgerClick}>
              <img src={hamburgerIcon} alt="menu_responsive" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default EDLBanner;
