import backgroundHome from "../assets/backgroundEDLIntranet2-1.png";
import "../styles/EDLHome.css";
import edlInstitutionnel from "../assets/EDL-logo.png";
import APEDL from "../assets/AP-EDL5.png";

import verifierFacture from "../assets/verifier-facture.png";
import edlFaq from "../assets/faq-referent.png";
import dashboardIcon from "../assets/icon-dashboard.png";
import nousContacter from "../assets/nous-contacter.png";
import edlReservationSalle from "../assets/reucalendar (2).png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function InProgress({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected }) {
  const baseUrl = window.location.origin;

  return (
    <div
      className="container-Home"
      style={{ "background-image": `url(${APEDL})`, "min-height": "100vh", "background-repeat": "no-repeat", "background-size": "cover" }}
      onClick={() => setResponsiveMenuVisible(false)}>
      <div className="EDL-home-title">
        <p>ENERGIES DE LOIRE 🚧</p>
        <h1>Cette section est en cours de développement</h1>
      </div>
      <div className="flex-container-home">
        <div className="flex-subcontainer-home">
          <div className="flex-vertical-subcontainer-home">
            <a className="btn-get-started-home" href="./">
              <img src={dashboardIcon} alt="partenaire-dashboard" />
              <h4>Retour vers l'accueil</h4>
              <p>Accueil du portail partenaire EDL</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InProgress;
