import { useEffect, useState } from "react";
import htmlReactParser from "html-react-parser";

function FaqContent({ faqMarkdown }) {
  const [listOfComponent, setListOfComponent] = useState([]);

  console.log("faqMarkdown", faqMarkdown);

  function transformTextToArray(mkd) {
    if (mkd) {
      //Another way to markdown to jsx
      let textToDisplay = mkd;
      if (textToDisplay) {
        //Gestion des listes ordonnées (numérotées)
        let nbList = textToDisplay.match(/\n1. ([a-z]|[\s\S])+?\n[^\d{1,2}]/gm); //Récupère toutes les liste de nombres
        // console.log(nbList);
        if (nbList) {
          nbList.forEach((e) => {
            e = e.replace("\n1", "\nnlist");
            e = e.replace(/\n\d{1,2}/gm, "nlist");
            // console.log(e);
            textToDisplay = textToDisplay.replace(/\n1. ([a-z]|[\s\S])+?\n[^\d{1,2}]/, e);
          });
          console.log(textToDisplay);
        }

        //Gestion des listes à puce
        let puceList = textToDisplay.match(/\n- ([a-z]|[\s\S])+?\n[^-]/gm); //Récupère toutes les liste à puces
        // console.log(puceList);
        if (puceList) {
          puceList.forEach((e) => {
            e = e.replace("\n-", "\nplist");
            e = e.replace(/\n-/gm, "plist");
            // console.log(e);
            textToDisplay = textToDisplay.replace(/\n- ([a-z]|[\s\S])+?\n[^-]/, e);
          });
          //   console.log(textToDisplay);
        }

        let arrText = textToDisplay.split("\n");
        return arrText;
      }
    }
  }

  useEffect(() => {
    // console.log(faqMarkdown);
    let arrayOfText = transformTextToArray(faqMarkdown);
    // console.log(arrayOfText);
    setListOfComponent(arrayOfText);
  }, []);

  return (
    <div className="faq-detail">
      {listOfComponent && listOfComponent.length > 0 ? (
        listOfComponent.map((elt, index) => {
          //   console.log(elt);
          if (elt.match(/(### )/gm)) {
            return <h3>{elt.replace(/(### )/gm, "")}</h3>;
          } else if (elt.match(/(## )/gm)) {
            return <h2>{elt.replace(/(## )/gm, "")}</h2>;
          } else if (elt.match(/(# )/gm)) {
            return <h1>{elt.replace(/(# )/gm, "")}</h1>;
          } else if (elt.match(/<\/?[a-z][\s\S]*>/gm)) {
            //Repère les balises html

            return htmlReactParser(elt);
          } else if (elt.match(/\[(.*?)\]\((.*?)\)/gm)) {
            //Repère les liens type <a>
            let textPart = elt.match(/\[(.*?)\]/gm)[0].replace(/\[|\]/gm, "");
            let linkPart = elt.match(/\((.*?)\)/gm)[0].replace(/\(|\)/gm, "");
            return <a href={linkPart}>{textPart}</a>;
          } else if (elt.match(/nlist. /g)) {
            return (
              <ol>
                {elt.split(/nlist. /g).map((e) => {
                  //   console.log(e);
                  if (e) {
                    return <li>{e}</li>;
                  }
                  return null;
                })}
              </ol>
            );
          } else if (elt.match(/plist /g)) {
            return (
              <ul>
                {elt.split(/plist /g).map((e) => {
                  //   console.log(e);
                  if (e) {
                    return <li>{e}</li>;
                  }
                  return null;
                })}
              </ul>
            );
          } else if (elt === "") {
            //Prise en compte des sauts de ligne sans utilisation de <br /> dans Airtable
            return (
              <div>
                <br /> <br />
              </div>
            );
          } else if (elt.match(/\*\*([a-z]|[\s\S])*\*\*/gm)) {
            //Permet de repérer si une portion d'elt est en gras => **azerty**
            return (
              <p>
                {" "}
                {elt.split(/\*\*/gm).map((e, index) => {
                  return index % 2 == 1 ? <strong>{e}</strong> : e;
                })}
              </p>
            );
          } else if (elt.match(/_([a-z]|[\s\S])*_/gm)) {
            //Permet de repérer si une portion d'elt est en gras => **azerty**
            return (
              <p>
                {" "}
                {elt.split(/_/gm).map((e, index) => {
                  return index % 2 == 1 ? <em>{e}</em> : e;
                })}
              </p>
            );
          }
          return elt;
        })
      ) : (
        <p>Rien à afficher.</p>
      )}
    </div>
  );
}

export default FaqContent;
