import "../../styles/Dashboard.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Markdown from "markdown-to-jsx";
import htmlReactParser from "html-react-parser";
// import { bulkDownloadAttachment } from "../../js/dashboard.ts";

const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl;
// console.log("baseUrl4200", baseUrl4200);

if (baseUrl4200.includes("3001")) {
  baseUrl4200 = baseUrl.replace("3001", "4200");
} else if (baseUrl.includes("partenaire-preprod")) {
  baseUrl4200 = baseUrl4200.replace("partenaire-preprod", "api");
} else if (baseUrl.includes("partenaire")) {
  baseUrl4200 = baseUrl4200.replace("partenaire", "api");
}

function DashboardContent({ selectedRecord, user, setUser, loading, setLoading, loadingMsg, setLoadingMsg }) {
  const [listOfComponent, setListOfComponent] = useState([]);

  function downloadBufferZipFile(buffer, fileName) {
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (selectedRecord.Text) {
      //Another way to markdown to jsx
      let textToDisplay = selectedRecord.Text;
      if (textToDisplay) {
        //Gestion des listes ordonnées (numérotées)
        let nbList = textToDisplay.match(/\n1. ([a-z]|[\s\S])+?\n[^\d{1,2}]/gm); //Récupère toutes les liste de nombres
        console.log(nbList);
        if (nbList) {
          nbList.forEach((e) => {
            e = e.replace("\n1", "\nnlist");
            e = e.replace(/\n\d{1,2}/gm, "nlist");
            console.log(e);
            textToDisplay = textToDisplay.replace(/\n1. ([a-z]|[\s\S])+?\n[^\d{1,2}]/, e);
          });
          console.log(textToDisplay);
        }

        //Gestion des listes à puce
        let puceList = textToDisplay.match(/\n- ([a-z]|[\s\S])+?\n[^-]/gm); //Récupère toutes les liste à puces
        console.log(puceList);
        if (puceList) {
          puceList.forEach((e) => {
            e = e.replace("\n-", "\nplist");
            e = e.replace(/\n-/gm, "plist");
            console.log(e);
            textToDisplay = textToDisplay.replace(/\n- ([a-z]|[\s\S])+?\n[^-]/, e);
          });
          console.log(textToDisplay);
        }

        let arrText = textToDisplay.split("\n");

        setListOfComponent(arrText);
        console.log(arrText);
      }
    }
  }, [selectedRecord]);

  let bulkDownloadAttachment = (params) => {
    //model => params = {
    //     table:string,
    //     view:string
    // }
    console.log(params);
    setLoading(true);
    let recId = selectedRecord.id;

    setLoadingMsg("Quelques vérifications sont en cours pour garantir la sécurité de nos données.");
    axios
      .post(
        baseUrl4200 + "/airtableReq/authorizedPartenaireRequest",
        {
          baseId: "appqe0gy4OaaiygZZ",
          tableName: "Portail partenaire",
          function: "bulkDownloadAttachment",
          fields: params.fields,
          recId: recId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .catch((error) => {
        setLoading(false);
        setLoadingMsg("");
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          // 👇️ error: AxiosError<any, any>
          // return error;
        } else {
          console.log("unexpected error: ", error);
          // return { error: "An unexpected error occurred" };
        }
        return;
      })
      .then((respo) => {
        if (!respo) {
          // console.log("respo", respo);
          return;
        }
        let data = respo;
        console.log(data);
        setLoading(true);

        setLoadingMsg("Votre téléchargement est en cours. Cela peut prendre quelques instants.");

        axios
          .post(
            baseUrl4200 + "/airtableReq/bulkDownloadAttachment",
            {
              baseId: "appqe0gy4OaaiygZZ",
              tableName: params.table,
              viewName: params.view,
              fields: params.fields,
              userId: user.user._id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              responseType: "blob",
              onDownloadProgress: (progressEvent) => {
                const mbCompleted = Math.round(progressEvent.loaded / 1024 / 1024);
                setLoadingMsg(`Téléchargement en cours - ${mbCompleted} MB`);
              },
            }
          )
          .then((response) => {
            const blob = response.data;
            const totalBytes = blob.size;
            let downloadedBytes = 0;
            const chunkSize = 1024 * 1024; // 1 MB chunk size
            const dataArray = [];

            const fileReader = new FileReader();
            fileReader.onload = function () {
              const chunkArray = new Uint8Array(this.result);
              dataArray.push(chunkArray);

              // Update the downloadedBytes count and the progress bar
              downloadedBytes += chunkArray.length;
              const progress = Math.round((downloadedBytes / totalBytes) * 100);
              setLoadingMsg(`Préparation du fichier compressé (Zip) - ${progress}%`);

              console.log(`Downloaded ${progress}%`);

              // If there are more bytes to download, read the next chunk
              if (downloadedBytes < totalBytes) {
                readNextChunk();
              } else {
                setLoading(false);
                setLoadingMsg("");

                // All bytes have been downloaded, concatenate the chunks
                const concatenatedArray = new Uint8Array(downloadedBytes);
                let offset = 0;
                for (let i = 0; i < dataArray.length; i++) {
                  concatenatedArray.set(dataArray[i], offset);
                  offset += dataArray[i].length;
                }

                // Create the download link
                const blobUrl = URL.createObjectURL(new Blob([concatenatedArray]));
                const downloadLink = document.createElement("a");
                downloadLink.href = blobUrl;
                downloadLink.download = "file.zip";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }
            };

            function readNextChunk() {
              const chunk = blob.slice(downloadedBytes, downloadedBytes + chunkSize);
              fileReader.readAsArrayBuffer(chunk);
            }

            // Start reading the first chunk
            readNextChunk();
          })
          .catch((error) => {
            setLoading(false);
            setLoadingMsg("");
            if (axios.isAxiosError(error)) {
              console.log("error message: ", error.message);
            } else {
              console.log("unexpected error: ", error);
            }
          });

        // axios
        //   .post(
        //     baseUrl4200 + "/airtableReq/bulkDownloadAttachment",
        //     {
        //       baseId: "appqe0gy4OaaiygZZ",
        //       tableName: params.table,
        //       viewName: params.view,
        //       fields: params.fields,
        //       userId: user.user._id,
        //     },
        //     {
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${user.token}`,
        //       },
        //       responseType: "blob", // set the response type to 'stream',
        //       onDownloadProgress: (progressEvent) => {
        //         console.log("progressEvent", progressEvent);
        //         const mbCompleted = Math.round(progressEvent.loaded / 1024 / 1024);
        //         setLoadingMsg(`Téléchargement en cours - ${mbCompleted} MB`);

        //       },
        //     }
        //   )
        //   .catch((error) => {
        //     setLoading(false);
        //     setLoadingMsg("");
        //     if (axios.isAxiosError(error)) {
        //       console.log("error message: ", error.message);
        //       // 👇️ error: AxiosError<any, any>
        //       // return error;
        //     } else {
        //       console.log("unexpected error: ", error);
        //       // return { error: "An unexpected error occurred" };
        //     }
        //   })
        //   .then((response) => {
        //     // console.log(response);

        //     const blob = response.data;
        //     const totalBytes = blob.size;
        //     let downloadedBytes = 0;
        //     const chunkSize = 1024 * 1024; // 1 MB chunk size
        //     const dataArray = new Uint8Array(totalBytes);

        //     const fileReader = new FileReader();
        //     fileReader.onload = function () {
        //       // Read the loaded chunk into the dataArray
        //       dataArray.set(new Uint8Array(this.result), downloadedBytes);

        //       // Update the downloadedBytes count and the progress bar
        //       downloadedBytes += chunkSize;
        //       const progress = Math.round((downloadedBytes / totalBytes) * 100);
        //       setLoadingMsg(`Préparation du fichier compressé (Zip) - ${progress}%`);

        //       console.log(`Downloaded ${progress}%`);

        //       // If there are more bytes to download, read the next chunk
        //       if (downloadedBytes < totalBytes) {
        //         readNextChunk();
        //       } else {
        //         setLoading(false);
        //         setLoadingMsg("");
        //         // All bytes have been downloaded, create the download link
        //         const blobUrl = URL.createObjectURL(new Blob([dataArray]));
        //         const downloadLink = document.createElement("a");
        //         downloadLink.href = blobUrl;
        //         downloadLink.download = "file.zip";
        //         document.body.appendChild(downloadLink);
        //         downloadLink.click();
        //         document.body.removeChild(downloadLink);
        //       }
        //     };

        //     function readNextChunk() {
        //       const chunk = blob.slice(downloadedBytes, downloadedBytes + chunkSize);
        //       fileReader.readAsArrayBuffer(chunk);
        //     }

        //     // Start reading the first chunk
        //     readNextChunk();

        //   });
        // .then((response) => {
        //   let data = response.data;
        //   console.log(data);

        //   // return data;
        //   if (data.downloadZipLink) {
        //     setLoading(true);
        //     // if (data.downloadZipLink.indexOf("localhost")>-1)
        //     axios
        //       .get(data.downloadZipLink, {
        //         headers: {
        //           "Content-Type": "application/zip",
        //         },
        //         responseType: "blob",
        //       })
        //       .catch((err) => {
        //         console.log("error", err);
        //         setLoading(false);
        //       })
        //       // .then((response) => response.blob())
        //       .then((blob) => {
        //         // Create blob link to download
        //         console.log(blob);
        //         const url = window.URL.createObjectURL(new Blob([blob.data]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         link.setAttribute("download", `bulkDownload.zip`);

        //         // Append to html link element page
        //         document.body.appendChild(link);

        //         // Start download
        //         link.click();

        //         // Clean up and remove the link
        //         if (link.parentNode) link.parentNode.removeChild(link);
        //         setLoading(false);
        //         setLoadingMsg("");
        //       });
        //   }
        // });
      });
  };

  function createCustomElement(elt = "") {
    console.log("customElement", elt);
    if (elt.match(/<([A-Z]|[a-z])+ ?\/?>?/gm)) {
      let htmlBalise = elt.match(/<([A-Z]|[a-z])+ ?\/?>?/gm)[0].replace(/<|>|\/| /gm, "");
      let innerHtmlArr = elt.match(/<\/?[a-z][\s\S]*>/gm)[0].split(/([^\ ]([a-z]|[A-Z])+?=\".+?\")/g);
      console.log(htmlBalise);
      console.log(innerHtmlArr);

      let attributes = [];
      let htmlProps = {};
      if (innerHtmlArr.length > 0) {
        for (let e of innerHtmlArr) {
          if (e.indexOf("=") > -1) {
            e = e.replace(/[\\"]+/g, "");
            attributes.push({ key: e.split("=")[0], val: e.split("=")[1] });
            htmlProps[e.split("=")[0]] = e.split("=")[1];
          }
        }
        if (htmlBalise === "h1") {
          return <h1 {...htmlProps}></h1>;
        } else if (htmlBalise === "input") {
          console.log("htmlProps", htmlProps);
          // console.log("test", bulkDownloadAttachment({ test: "hellTest" }));
          if (htmlProps.customFunction) {
            let fields = htmlProps.customFunction
              .match(/(\(.+?\))/g)[0]
              .replace(/, /g, ",")
              .replace(/[\(\)]/g, "")
              .split(",");
            htmlProps.customFunction = htmlProps.customFunction.split(/\(/)[0];
            if (htmlProps.customFunction === "bulkDownloadAttachment") {
              return (
                <input
                  {...htmlProps}
                  onClick={() => bulkDownloadAttachment({ table: htmlProps.table, view: htmlProps.view, fields: fields })}
                ></input>
              );
            }
          }
        }
      }
    } else {
      return null;
    }
  }

  return (
    <div className="dashboard-detail">
      {/* <Markdown>{selectedRecord.Text}</Markdown> */}
      {listOfComponent.length > 0 ? (
        listOfComponent.map((elt, index) => {
          console.log(elt);
          if (elt.match(/(### )/gm)) {
            return <h3>{elt.replace(/(### )/gm, "")}</h3>;
          } else if (elt.match(/(## )/gm)) {
            return <h2>{elt.replace(/(## )/gm, "")}</h2>;
          } else if (elt.match(/(# )/gm)) {
            return <h1>{elt.replace(/(# )/gm, "")}</h1>;
          } else if (elt.match(/<\/?[a-z][\s\S]*>/gm)) {
            //Repère les balises html
            if (elt.match(/customFunction/gm)) {
              //repère s'il s'agit d'une customFunction
              return createCustomElement(elt);
            }
            return htmlReactParser(elt);
          } else if (elt.match(/\[(.*?)\]\((.*?)\)/gm)) {
            //Repère les liens type <a>
            let textPart = elt.match(/\[(.*?)\]/gm)[0].replace(/\[|\]/gm, "");
            let linkPart = elt.match(/\((.*?)\)/gm)[0].replace(/\(|\)/gm, "");
            return <a href={linkPart}>{textPart}</a>;
          } else if (elt.match(/nlist. /g)) {
            return (
              <ol>
                {elt.split(/nlist. /g).map((e) => {
                  console.log(e);
                  if (e) {
                    return <li>{e}</li>;
                  }
                  return null;
                })}
              </ol>
            );
          } else if (elt.match(/plist /g)) {
            return (
              <ul>
                {elt.split(/plist /g).map((e) => {
                  console.log(e);
                  if (e) {
                    return <li>{e}</li>;
                  }
                  return null;
                })}
              </ul>
            );
          } else if (elt === "") {
            //Prise en compte des sauts de ligne sans utilisation de <br /> dans Airtable
            return (
              <div>
                <br /> <br />
              </div>
            );
          } else if (elt.match(/\*\*([a-z]|[\s\S])*\*\*/gm)) {
            //Permet de repérer si une portion d'elt est en gras => **azerty**
            return (
              <p>
                {" "}
                {elt.split(/\*\*/gm).map((e, index) => {
                  return index % 2 == 1 ? <strong>{e}</strong> : e;
                })}
              </p>
            );
          } else if (elt.match(/_([a-z]|[\s\S])*_/gm)) {
            //Permet de repérer si une portion d'elt est en gras => **azerty**
            return (
              <p>
                {" "}
                {elt.split(/_/gm).map((e, index) => {
                  return index % 2 == 1 ? <em>{e}</em> : e;
                })}
              </p>
            );
          }
          return elt;
        })
      ) : (
        <p>Rien à afficher.</p>
      )}
    </div>
  );
}
export default DashboardContent;
