import { useState, useEffect } from "react";
import APEDL from "../assets/AP-EDL5.png";
import photoHome from "../assets/realisation1.jpg";

import "../styles/Connexion.css";
import axios from "axios";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Connexion({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected }) {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [firstVisit, setFirstVIsit] = useState(false);
  const [wrongPwd, setWrongPwd] = useState(false);
  const [unactivatedAcount, setUnactivatedAcount] = useState(false);
  const [UserNotFoundOrUnactivatedAcount, setUserNotFOundOrUnactivatedAcount] = useState(false);
  const [activationAcount, setActivationAcount] = useState(false);
  const [userProject, setUserProject] = useState({
    userEmail: "",
    centraleName: "",
    projectName: "",
    token: "",
    company: "",
  });
  const [validMail, setValidMail] = useState("");
  const [forgotPwd, setForgotPwd] = useState("");
  const [problemOccured, setProblemOccured] = useState("");
  // const [isActivated, setIsActivated] = useState(false);

  let pwdRecovery = { key: "", id: "", resetPwd: false };
  let isActivated = false;

  const params = new URLSearchParams(window.location.search);

  const { height, width } = useWindowDimensions();

  if (params.has("key") && params.has("id")) {
    pwdRecovery.key = params.get("key");
    pwdRecovery.id = params.get("id");
    pwdRecovery.resetPwd = true;
  }

  if (params.has("activated")) {
    isActivated = true;
  }

  const mailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const baseUrl = window.location.origin;

  const handleSendEmail = (e) => {
    //Description :
    //1 - Does the user exist in Airtable Base EDL > Table Contact > View Partenaires (portail) (Check if user has something to see in his portal)
    //2 - Does the user exist in MongoDb (Strong authentication by Password)
    // If 1 & 2 are true, then allow connection and redirect user to his home page.
    // If only 1 is true, then create user in MongoDb
    // If 1 is false, then refuse connection and invite to contact it@energiesdeloire.com

    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;

    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }
    setValidMail("");

    //Vérifier que les identifiants sont renseignés avant envoi inutile.
    axios
      .post(
        baseUrl + "/api/auth/getUser",

        { email: userMail, pwdRecovery },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )

      .then((response) => {
        if (response.status === 200) {
          setUserNotFOundOrUnactivatedAcount(false);
        } else if (response.status === 201) {
          setUserNotFOundOrUnactivatedAcount(false);
        }

        var theRes = response.data;

        setLoading(false);
        setEmailSent(true);
        if (response.data.firstVisit === true) {
          setFirstVIsit(true);
        } else {
          setFirstVIsit(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            setLoading(false);
            return setUserNotFOundOrUnactivatedAcount(true);
          } else if (error.response.status === 403) {
            setLoading(false);
            if (!pwdRecovery.resetPwd) {
              return setUserNotFOundOrUnactivatedAcount(true);
            } else {
              return setProblemOccured(
                "Impossible de réinitialiser votre mot de passe. Merci de cliquer sur mot de passe oublié. Si cela se reproduit, merci d'en informer it@energiesdeloire.com"
              );
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
        return;
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    var userPwd = document.getElementById("pwdForm").value;
    var confirmPwdForm = document.getElementById("confirmPwdForm").value;

    if (userPwd !== confirmPwdForm) {
      alert("Les mots de passe renseignés ne sont pas identiques.");
      setLoading(false);
      return;
    }

    axios
      .post(
        baseUrl + "/api/auth/signup",
        // "https://app.energiesdeloire.com/api/auth/signup",
        // "http://localhost:3000/api/auth/signup",
        { email: userMail, password: userPwd, confirmPwd: confirmPwdForm },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setUserNotFOundOrUnactivatedAcount(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        console.log(response.data);
        var theRes = response.data;
        setLoading(false);

        if (response.status === 200) {
          setIsConnected(true);
          if (response.data.activeUser) {
            window.location.href = baseUrl + "/";
          } else {
            setActivationAcount(true);
          }
        } else if (response.status === 401) {
          setWrongPwd(true);
        } else {
          console.log("A problem occurred");
        }
      });
  };
  const handleSignin = (e) => {
    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    var userPwd = document.getElementById("pwdForm").value;

    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }

    setValidMail("");
    axios
      .post(
        baseUrl + "/api/auth/login",

        { email: userMail, password: userPwd },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .then((response) => {
        console.log(response.data);
        var theRes = response.data;

        setLoading(false);

        if (response.status === 200) {
          setIsConnected(true);
          window.location.href = baseUrl + "/";
        } else if (response.status === 401) {
          setWrongPwd(true);
          setIsConnected(false);
        } else {
          setUserNotFOundOrUnactivatedAcount(true);
          setIsConnected(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            if (error.response.data.error === "Mot de passe incorrect !") {
              setWrongPwd(true);
            }
          } else if (error.response.status === 403) {
            if (error.response.data.error === "Compte non activé !") {
              setUnactivatedAcount(true);
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      });
  };

  const handleForgotPwd = (e) => {
    e.preventDefault();
    setLoading(true);
    setProblemOccured("");
    var userMail = document.getElementById("emailForm").value;
    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }
    if (!userMail.match(mailRegex)) {
      setLoading(false);
      setValidMail("L'adresse mail renseignée n'est pas valide");
      return;
    }
    setValidMail("");

    axios
      .post(
        baseUrl + "/api/auth/forgotPwd",

        { email: userMail },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setForgotPwd("Vous allez recevoir un email pour réinitialiser votre mot de passe d'ici quelques instants.");
        } else {
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        }

        console.log(response.data);
        var theRes = response.data;

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
            return setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
          } else if (error.response.status === 403) {
            setLoading(false);
            return setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        }
        console.log(error.config);
        setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        setLoading(false);
        return;
      });
  };

  const handleReactivateAcount = (e) => {
    e.preventDefault();
    setLoading(true);
    setProblemOccured("");
    var userMail = document.getElementById("emailForm").value;
    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }
    if (!userMail.match(mailRegex)) {
      setLoading(false);
      setValidMail("L'adresse mail renseignée n'est pas valide");
      return;
    }
    setValidMail("");

    axios
      .post(
        baseUrl + "/api/auth/reactivateAcount",

        { email: userMail },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setActivationAcount(true);
        } else {
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        }

        console.log(response.data);
        var theRes = response.data;

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
            return setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
          } else if (error.response.status === 403) {
            setLoading(false);
            return setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        }
        console.log(error.config);
        setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        setLoading(false);
        return;
      });
  };

  return (
    <div
      className="container-Home"
      // style={{ "background-image": `url(${APEDL})`, "min-height": "100vh", "background-repeat": "no-repeat", "background-size": "cover" }}
      onClick={() => setResponsiveMenuVisible(false)}
    >
      {loading ? <div id="spinner-modal"></div> : null}

      {/* <div className="background-div">
        <img className="background-img" src={backgroundHome} alt="background-EDL" />
      </div> */}

      <div
        className="EDL-home-title"
        style={{
          justifyContent: width / height < 0.84 || width / height > 1.5 ? "unset" : "space-around",
          "background-image": `url(${photoHome})`,
          "background-repeat": "no-repeat",
          "background-size": "cover",
          height: "100vh",
          "flex-wrap": "wrap",
          "min-height": "700px",
        }}
      >
        <p>ESPACE PARTENAIRE - ENERGIES DE LOIRE</p>
        <h1>Connexion</h1>
        <div class="login-card">
          {!pwdRecovery.resetPwd ? <h2>Accéder à votre espace partenaire</h2> : <h2>Réinitialiser votre mot de passe</h2>}
          {isActivated ? <p>✔️ Votre compte utilisateur a bien été activé ! Vous pouvez désormais vous connectez à votre espace.</p> : null}
          {!activationAcount ? (
            <form>
              <div className="div-item">
                <label for="email">Email</label>
                <input id="emailForm" type="email" name="email" required />
              </div>
              {emailSent ? (
                <div className="div-item">
                  <label for="password">Mot de passe</label>
                  <input type="password" name="password" required id="pwdForm" placeholder="Tapez votre mot de passe" />
                </div>
              ) : null}
              {firstVisit ? (
                <div className="div-item">
                  <label for="password">Confirmer le mot de passe</label>
                  <input type="password" name="password" required id="confirmPwdForm" placeholder="Tapez de nouveau votre mot de passe" />
                </div>
              ) : null}
              {UserNotFoundOrUnactivatedAcount ? (
                <div>
                  <p style={{ color: "red" }}>Utilisateur non trouvé ou compte non activé. Merci de contacter it@energiesdeloire.com</p>
                </div>
              ) : null}
              {/* {!createAcountAsked ? ( */}
              <div>
                {" "}
                {!emailSent ? (
                  <div className="div-item" id="form-button">
                    <button className="formButton" onClick={handleSendEmail}>
                      Se connecter
                    </button>
                    <br />
                    <a href="" onClick={handleForgotPwd}>
                      Mot de passe oublié ?
                    </a>
                    {validMail !== "" ? <p style={{ color: "red" }}>{validMail}</p> : null}
                    {forgotPwd !== "" ? <p style={{ color: "red" }}>{forgotPwd}</p> : null}
                    {problemOccured !== "" ? <p style={{ color: "red" }}>{problemOccured}</p> : null}
                  </div>
                ) : (
                  <div>
                    {firstVisit ? (
                      <div className="div-item" id="form-button">
                        <button className="formButton" onClick={handleSignup}>
                          Se connecter
                        </button>
                        <br />
                        <a href="" onClick={handleForgotPwd}>
                          Mot de passe oublié ?
                        </a>
                        {validMail !== "" ? <p style={{ color: "red" }}>{validMail}</p> : null}
                        {forgotPwd !== "" ? <p style={{ color: "red" }}>{forgotPwd}</p> : null}
                      </div>
                    ) : (
                      <div className="div-item">
                        {wrongPwd ? <p style={{ color: "red" }}>Le mot de passe renseigné n'est pas correct.</p> : null}
                        {unactivatedAcount ? (
                          <div>
                            <p style={{ color: "red" }}>
                              ❌ Le compte utilisateur n'est pas encore activé. Pour recevoir un nouveau lien d'activation, merci de cliquer
                              sur ce{" "}
                              <a href="" onClick={handleReactivateAcount}>
                                lien
                              </a>
                              .
                            </p>
                            <p>Si le problème persiste, merci de contacter it@energiesdeloire.com</p>
                          </div>
                        ) : null}
                        <button className="formButton" id="form-button" onClick={handleSignin}>
                          Se connecter
                        </button>
                        <br />
                        <a href="" onClick={handleForgotPwd}>
                          Mot de passe oublié ?
                        </a>
                        {validMail !== "" ? <p style={{ color: "red" }}>{validMail}</p> : null}
                        {forgotPwd !== "" ? <p style={{ color: "red" }}>{forgotPwd}</p> : null}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* // ) : null} */}
            </form>
          ) : (
            <p>
              ✔️ Vous allez recevoir un email de confirmation dans quelques instants vous permettant d'activer votre compte partenaire EDL.
              <br />
              Merci d'activer votre compte en suivant les instructions de cet email.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Connexion;
