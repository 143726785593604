import backgroundHome from "../assets/backgroundEDLIntranet2-1.png";
import "../styles/EDLHome.css";
import edlInstitutionnel from "../assets/EDL-logo.png";
import photoHome from "../assets/realisation1.jpg";
// import APEDL from "../assets/AP-EDL5.png";

import verifierFacture from "../assets/verifier-facture.png";
import edlFaq from "../assets/faq-referent.png";
import dashboardIcon from "../assets/icon-dashboard.png";
import nousContacter from "../assets/nous-contacter.png";
import edlReservationSalle from "../assets/reucalendar (2).png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Home({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const baseUrl = window.location.origin;
  const { height, width } = useWindowDimensions();

  const [specificInterlocuteurs, setSpecificInterlocuteurs] = useState([]);
  useEffect(() => {
    // Vient vérifier à quel groupe d'utilisateur appartient l'utilisateur
    if (user) {
      if (user.user) {
        if (user.user.interlocuteursEDL) {
          if (user.user.interlocuteursEDL.length > 0) {
            axios
              .post(
                baseUrl + "/api/auth/getSpecificInterlocuteurs",
                { userId: user.user._id, interlocuteursId: user.user.interlocuteursEDL },
                {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                  },
                  mode: "cors",
                }
              )
              .catch((err) => {
                console.log(err);
              })
              .then((res) => {
                console.log(res);
                if (res.status === 200) {
                  console.log(res.data);
                  setSpecificInterlocuteurs(res.data.interlocuteurs);
                }
              });
          }
        }
      }
    }
  }, [user]);

  return (
    <div className="container-Home" onClick={() => setResponsiveMenuVisible(false)}>
      <div
        className="EDL-home-title"
        style={{
          justifyContent: width / height < 0.84 || width / height > 1.5 ? "unset" : "space-around",
          "background-image": `url(${photoHome})`,
          "background-repeat": "no-repeat",
          "background-size": "cover",
          height: "100vh",
          "flex-wrap": "wrap",
          "min-height": "700px",
        }}
      >
        <div
          className="sub-EDL-home-title"
          style={{
            // justifyContent: width / height < 0.8 || width / height > 1.4 ? "unset" : "space-around",
            justifyContent: width / height > 0.8 || width / height < 1.4 || width < 400 ? "space-around" : "unset",

            "background-color": "#55555559",
            display: "flex",
            "flex-direction": "column",
            height: "100%",
          }}
        >
          {/* <h1>Portail partenaire</h1> */}
          <h1>{user.user !== undefined ? user.user.company[0] + " - " : null} ENERGIES DE LOIRE</h1>

          <div className="title-inner-div">
            <div
              className="sub-title-inner-div"
              style={{ overflow: "auto", background: "#ffffffa3", "border-radius": "5px", padding: "10px" }}
            >
              <p>Bienvenue dans votre espace partenaire Energies De Loire, spécialiste de l'énergie photovoltaïque !</p> <br></br>
              {width > 750 ? (
                <p>
                  Nous sommes ravis de vous accueillir ici, dans cet espace dédié à nos partenaires, où vous pourrez accéder à toutes les
                  ressources dont vous avez besoin pour réussir vos projets solaires.
                </p>
              ) : null}
              {width < 750 || width / height < 1 || (height < 735 && width < 1140) ? null : (
                <div>
                  <p>
                    Notre objectif est de fournir des solutions photovoltaïques innovantes, durables et rentables, tout en collaborant avec
                    des partenaires engagés à promouvoir une transition énergétique propre et efficace. Sur cet espace, vous trouverez une
                    mine d'informations pour vous accompagner tout au long de votre parcours avec nous.
                  </p>
                  <p>
                    Nous sommes déterminés à travailler en étroite collaboration avec vous pour bâtir un avenir énergétique durable.
                    Ensemble, nous pouvons faire une réelle différence en favorisant l'adoption massive de l'énergie solaire sur nos
                    territoires.
                  </p>
                </div>
              )}
            </div>
            <div
              className="sub-title-inner-div"
              style={{ display: "flex", "flex-direction": "column", "padding-top": " calc(10% - 50px)" }}
            >
              {width < 750 || width / height < 1 || (height < 735 && width < 1140) || !user.user || !user.user.logo ? null : (
                <div style={{ "text-align": "center" }}>
                  <img
                    src={user.user.logo[0].url}
                    alt={user.user.logo[0].filename}
                    style={{ width: "250px", "max-height": "150px", "border-radius": "50px", "object-fit": "cover" }}
                  />
                </div>
              )}

              <h2 style={{ color: "white" }}>ACCEDER À MES OUTILS</h2>
              <div className="flex-container-home">
                <div className="flex-subcontainer-home">
                  <div className="flex-vertical-subcontainer-home" style={{ display: "flex", "flex-direction": "column-reverse" }}>
                    <a className="btn-get-started-home" href="./dashboard">
                      <h4>Mes projets</h4>
                      {width > 650 ? <p>Retrouver l'ensemble des informations sur mes projets</p> : null}
                    </a>
                    <img className="img-above-button" src={dashboardIcon} alt="partenaire-dashboard" />
                  </div>
                </div>
                <div className="flex-subcontainer-home">
                  <div className="flex-vertical-subcontainer-home" style={{ display: "flex", "flex-direction": "column-reverse" }}>
                    <a className="btn-get-started-home" href="./faq">
                      <h4>FAQ Partenaire</h4>
                      {width > 650 ? <p>Une FAQ pour répondre à l'ensemble de vos questions !</p> : null}
                    </a>
                    <img className="img-above-button" src={edlFaq} alt="partenaire-faq" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2>MES INTERLOCUTEURS</h2>
      <div id="contact" className="flex-container-home-below">
        <div className="flex-subcontainer-home-below">
          <div className="flex-vertical-subcontainer-home">
            <ul>
              <h4>EDL - Candé</h4>
              <li>📞 Tél : 02 72 09 99 96</li>
              <li>
                ✉️ Email :
                <a href="mailto:contact@energiesdeloire.com?subject=Prise de contact" type="mail">
                  contact@energiesdeloire.com
                </a>
              </li>
              <li>📍 Adresse : ZI la Ramée, 49440 Candé</li>
            </ul>
            <ul>
              <h4>EDL - La Baule</h4>
              <li>📞 Tél : 02 72 09 99 97</li>
              <li>
                ✉️ Email :
                <a href="mailto:contact@energiesdeloire.com?subject=Prise de contact" type="mail">
                  contact@energiesdeloire.com
                </a>
              </li>
              <li>📍 Adresse : 136 Av. des Ondines, 44500 La Baule-Escoublac</li>
            </ul>
          </div>
        </div>
        {specificInterlocuteurs.length > 0 ? (
          <div className="flex-subcontainer-home-below">
            <div className="flex-vertical-subcontainer-home-below">
              {specificInterlocuteurs.map((interlocuteur) => {
                // let interlocuteurMail = interlocuteur["Email"];

                return (
                  <ul>
                    <div style={{ display: "flex", "align-items": "center", "justify-content": "start" }}>
                      {interlocuteur["Photo"] ? (
                        <img
                          src={interlocuteur["Photo"][0].url}
                          style={{ width: "50px", height: "50px", "margin-right": "10px", "border-radius": "100px", "object-fit": "cover" }}
                        ></img>
                      ) : null}
                      <span>
                        <span>
                          <strong>{interlocuteur["Name"]}</strong> -{" "}
                        </span>{" "}
                        <>&nbsp;</>
                        <span>
                          {interlocuteur["Fonction"] &&
                            interlocuteur["Fonction"].map((fcn, index) => {
                              if (index > 0) return ", " + fcn;
                              else return fcn;
                            })}
                        </span>
                      </span>
                    </div>
                    {/* <li>{interlocuteur["Fonction"]}</li> */}
                    <li>📞 Tél : {interlocuteur["Télephone"]}</li>
                    <li>
                      ✉️ Email :{" "}
                      <a href={`mailto:${interlocuteur["Email"]}?subject=Prise de contact`} type="mail">
                        {interlocuteur["Email"]}
                      </a>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Home;
