import backgroundHome from "../assets/backgroundEDLIntranet2-1.png";
import "../styles/Dashboard.css";
import edlInstitutionnel from "../assets/EDL-logo.png";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

import DashboardContent from "./Dashboard/DashboardContent";

const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl;
// console.log("baseUrl4200", baseUrl4200);

if (baseUrl4200.includes("3001")) {
  baseUrl4200 = baseUrl.replace("3001", "4200");
} else if (baseUrl.includes("partenaire-preprod")) {
  baseUrl4200 = baseUrl4200.replace("partenaire-preprod", "api-preprod");
} else if (baseUrl.includes("partenaire")) {
  baseUrl4200 = baseUrl4200.replace("partenaire", "api");
}
// var baseUrl4200 = baseUrl.replace("3001", "4200");
// baseUrl4200 = baseUrl4200.replace("partenaire", "api");

function sendConnectionData(selectedRec, user) {
  if (user.user === undefined || !selectedRec) return;
  // console.log({ Portail: selectedRec.id, Partenaire: user.user.airtableId, Date: Date.now() });
  axios
    .post(
      baseUrl4200 + "/airtableReq/createRecord",
      {
        baseId: "appqe0gy4OaaiygZZ",
        tableName: "tbl768YJCULixFCzI",
        fields: { Portail: [selectedRec.id], Partenaire: [user.user.airtableId], Date: Date.now() },
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        mode: "cors",
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("connection datas sent successfully !");
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function Dashboard({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const [recordOptions, setRecordOptions] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  useEffect(() => {
    // Vient récupérer le contenu de chaque portail dans un tableau et les affiche
    if (user.user === undefined) return;
    user.user.portails.forEach((id) => {
      setLoading(true);
      axios
        .post(
          baseUrl4200 + "/airtableReq/getRecord",
          {
            baseId: "appqe0gy4OaaiygZZ",
            tableName: "Portail partenaire",
            recId: id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data);
            let tmpRecOpt = recordOptions;
            tmpRecOpt.push(res.data.record.fields);
            tmpRecOpt[tmpRecOpt.length - 1].id = res.data.record.id;
            tmpRecOpt.sort((a, b) => a["Name"].localeCompare(b["Name"]));
            setRecordOptions([]);
            setRecordOptions(tmpRecOpt);
            console.log(tmpRecOpt);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  }, [user]);

  const handleSelectOnChange = (e) => {
    e.preventDefault();
    if (document.getElementById("standard-select").value !== "") {
      var selectedRec = recordOptions.find((rec) => rec["Name"] === document.getElementById("standard-select").value);
      if (selectedRecord !== selectedRec) {
        setSelectedRecord(selectedRec);
        sendConnectionData(selectedRec, user);
      }
    } else {
      setSelectedRecord(null);
    }
  };

  return (
    <div className="container-Dashboard" onClick={() => setResponsiveMenuVisible(false)}>
      <div className="EDL-home-title">
        <p>{user.user !== undefined ? user.user.company[0] + " - " : null}ENERGIES DE LOIRE</p>
        <h2>Mes projets</h2>
      </div>
      {loading ? (
        <div>
          <div id="spinner-modal">
            <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>Merci de patienter...</h4>
            {loadingMsg !== "" ? (
              <p style={{ position: "fixed", top: "calc(50% + 30px)", left: "50%", transform: "translate(-50%, -50%)" }}>{loadingMsg}</p>
            ) : null}
          </div>
        </div>
      ) : null}

      <div className="standard-select-div">
        <p>Que souhaitez-vous afficher ?</p>
        <select id="standard-select" onChange={handleSelectOnChange}>
          <option value="">Sélectionner</option>
          {recordOptions.length > 0
            ? recordOptions.map((opt, i) => {
                return (
                  <option key={i} value={opt["Name"]}>
                    {opt["Name"]}
                  </option>
                );
              })
            : null}
        </select>
        <div className="three-color-separator"></div>
      </div>

      <div className="dashboard-content">
        {selectedRecord === null ? (
          <p>Merci de sélectionner un élément dans le menu déroulant ci-dessus.</p>
        ) : (
          <div style={{ width: "90%", margin: "0 auto" }}>
            <h2 style={{ color: "#f4af24", "font-size": "30px", "text-decoration": "underline" }}>{selectedRecord["Name"]}</h2>
            <br />
            <DashboardContent
              selectedRecord={selectedRecord}
              user={user}
              setUser={setUser}
              loading={loading}
              setLoading={setLoading}
              loadingMsg={loadingMsg}
              setLoadingMsg={setLoadingMsg}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
